import self from "../img/self.jpeg"
import mock1 from "../img/pandeyweb.webp"
import mock2 from "../img/vimal.webp"
import mock3 from "../img/pandeyprakashan.webp"
import mock4 from "../img/manemesh.webp"
import mock5 from "../img/travels.webp"
import mock6 from "../img/saini.webp"
import mock7 from "../img/billing.webp"
import mock8 from "../img/apnaindia.webp"
import mock9 from "../img/divya.webp"
import mock10 from "../img/pawkins.webp"
import mock11 from "../img/rbssiet.webp"
import mock12 from "../img/svsss.webp"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Vimal",
    lastName: "Pandey",
    initials: "JS", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Full Stack Developer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        // {
        //     emoji: '☕',
        //     text: ''
        // },
        {
            emoji: "📧",
            text: "mail@vimalpandey.in"
        },
        {
            emoji: '🌎',
            text: 'Mathura, Uttar Pradesh'
        },
        {
            emoji: "💼",
            text: "Full Stack Developer at Seotug"
        },
        {
            emoji: "🫣",
            text: "Full Stack Developer || Graphic Designer"
        },
        
        
    ],
    socials: [
        // {
        //     link: "https://facebook.com",
        //     icon: 'fa fa-facebook',
        //     label: 'facebook'
        // },
        {
            link: "https://www.instagram.com/pandey.vimall/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/vimalpandey041",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/vimal-pandey-431331204/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://twitter.com/vimallpandey",
            icon: "fa fa-twitter",
            label: 'twitter'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hello! I'm Vimal Pandey. I'm a Full Stack Developer at Seotug. I studied Computer Science & Engineering  at State Government College Rewari , I enjoy long walks on the beach, and I believe artificial intelligence will inevitably rule us all one day. You should hire me!",
    skills:
        {
            proficientWith: ['javascript', 'react','express','sass','redux','react-router','codeigniter','laravel','php','Corel Draw','photoshop','Tailwind CSS','Font Awesome','Swiper JS','Owl Carousel','UI & UX', 'C Language','C++', 'JAVA','jquery','datatable','API','Rest API','POSTMAN','Video Editing','Graphic Designing','After Effect','Final Cut Pro','Premire Pro', 'git', 'github', 'bootstrap', 'html5', 'css3', 'figma'],
            exposedTo: ['nodejs', 'python', 'adobe illustrator']
        }
    ,
    hobbies: [
        {
            label: 'Study',
            emoji: '📖'
        },
        {
            label: 'Development',
            emoji: '🎭'
        },
        {
            label: 'Music',
            emoji: '🎭'
        },
        {
            label: 'Explore Tech',
            emoji: '🎭'
        },
        {
            label: 'DSA',
            emoji: '🎥'
        },
        
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Project 1",
            live: "http://pandeywebsolution.in.net/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "#", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        },
        {
            title: "Project 2",
            live: "https://vimalpandey.in/",
            source: "#",
            image: mock2
        },
        {
            title: "Project 3",
            live: "http://pandeyprakashan.in.net/",
            source: "#",
            image: mock3
        },
        {
            title: "Project 4",
            live: "https://manmeshind.com/",
            source: "#",
            image: mock4
        },
        {
            title: "Project 5",
            live: "#",
            source: "#",
            image: mock5
        },
        {
            title: "Project 6",
            live: "http://sainicomputer.com/",
            source: "#",
            image: mock6
        },
        {
            title: "Project 7",
            live: "#",
            source: "#",
            image: mock7
        },
        {
            title: "Project 8",
            live: "apnaindiafoundation.com",
            source: "#",
            image: mock8
        },
        {
            title: "Project 9",
            live: "#",
            source: "#",
            image: mock9
        },
        {
            title: "Project 10",
            live: "https://pawkins.in",
            source: "#",
            image: mock10
        },
        {
            title: "Project 11",
            live: "#",
            source: "#",
            image: mock11
        },
        {
            title: "Project 12",
            live: "#",
            source: "#",
            image: mock12
        },
    ]
}